<template>
  <Page
    has-actions
    :subtitle="`${total} ${$tc('result', total <= 1 ? 1 : 2)}`"
    :loading="brands_loading"
  >
    <template
      v-if="$can('read', 'catalog.brands')"
      slot="actions"
    >
      <div slot="actions">
        <div class="field has-addons">
          <div class="is-flex mr-2">
            <b-button
              v-if="$device.mobile"
              size="is-small"
              rounded
              @click="showMobile()"
            >
              <b-icon
                icon="magnify"
                size="is-small"
              />
            </b-button>
            <b-input
              ref="search"
              v-model="search"
              :placeholder="$t('search')"
              icon="magnify"
              :size="$device.mobile ? 'is-small' : ''"
              :class="{ expand: searchFocused, 'hide-search': $device.mobile }"
              rounded
              @blur="hideMobile()"
            />
          </div>
          <b-dropdown
            aria-role="list"
            position="is-bottom-left"
            scrollable
            max-height="400px"
            append-to-body
          >
            <div slot="trigger">
              <b-button
                icon-left="dots-vertical"
                :size="$device.mobile ? 'is-small' : ''"
                type="is-default"
                rounded
              />
            </div>

            <b-dropdown-item
              v-if="$can('create', 'catalog.brands')"
              aria-role="listitem"
              has-link
            >
              <router-link to="/catalog/brands/create">
                <b-icon
                  icon="plus"
                  size="is-small"
                />
                {{ $t("new_brand") }}
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              :focusable="false"
            >
              <b-switch
                v-model="active"
                type="is-success"
              >
                {{
                  $tc("active", 2)
                }}
              </b-switch>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </template>
    <Card
      v-if="$can('read', 'catalog.brands')"
      content-class="c-table"
    >
      <b-table
        :loading="brands_loading"
        :data="brands"
        sotrable
        default-sort="id"
        paginated
        pagination-position="top"
        :per-page="perPage"
        :current-page="page"
        :total="total"
        :row-class="
          (row, index) => (row.id == updated_brand ? 'bg-updated' : '')
        "
      >
        <template v-for="head in headers">
          <b-table-column
            :key="head.value"
            v-slot="props"
            :label="head.text"
            :field="head.value"
            :sortable="head.sortable"
            :width="head.width"
            :centered="head.centered"
            header-class="sticky-header"
          >
            <template v-if="head.value == 'id'">
              <router-link
                v-if="$can('read', 'catalog.brands') || $can('update', 'catalog.brands')"
                :to="`/catalog/brands/${props.row.id}`"
              >
                {{ props.row.id }}
              </router-link>
              <span v-else>{{ props.row.id }}</span>
            </template>
            <template v-else-if="head.value == 'noindex'">
              <b-icon
                :icon="props.row.noindex ? 'check' : 'close'"
                custom-size="mdi-18px"
                :type="props.row.noindex ? 'is-success' : 'is-danger'"
              />
            </template>
            <template v-else-if="head.value == 'created_at.datetime'">
              {{ props.row.created_at && props.row.created_at.formatted }}
            </template>
            <template v-else-if="head.value == 'count.products'">
              {{ props.row.count.products }}
            </template>
            <template v-else-if="head.value == 'count.skus'">
              {{ props.row.count.skus }}
            </template>
            <template v-else-if="head.value == 'actions'">
              <b-dropdown
                :key="props.row.id"
                aria-role="list"
                position="is-bottom-left"
                append-to-body
              >
                <div slot="trigger">
                  <b-icon
                    icon="dots-vertical"
                    size="is-small"
                    class="is-clickable"
                  />
                </div>

                <b-dropdown-item
                  v-if="$can('read', 'catalog.brands') || $can('update', 'catalog.brands')"
                  aria-role="listitem"
                  has-link
                >
                  <router-link :to="`/catalog/brands/${props.row.id}`">
                    <b-icon
                      icon="eye"
                      size="is-small"
                    />
                    {{ $t("view") }}
                  </router-link>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="active && $can('delete', 'catalog.brands')"
                  aria-role="listitem"
                  @click="confirmDeleting(props.row.id)"
                >
                  <b-icon
                    icon="delete"
                    type="is-danger"
                    size="is-small"
                  />
                  {{ $t("delete") }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!active && $can('update', 'catalog.brands')"
                  aria-role="listitem"
                  @click="RestoreBrands(props.row.id)"
                >
                  <b-icon
                    icon="refresh"
                    type="is-success"
                    size="is-small"
                  />
                  {{ $t("restore") }}
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-else>
              {{ props.row[head.value] || "-" }}
            </template>
          </b-table-column>
        </template>
        <EmptyBlock
          slot="empty"
          icon="watermark"
        />
      </b-table>
      <Pagination
        :per-page="perPage"
        :per-pages="perPages"
        :total="total"
        :current-page="page"
        @update-per-page="val => (perPage = val)"
        @update-current-page="val => (page = val)"
      />
    </Card>
  </Page>
</template>

<script>
export default {
  data() {
    return {
      searchFocused: false,
      search: "",
      total: 0,
      perPages: [50, 100, 200],
      perPage: 50,
      page: 1,
      updated_brand: null,
      brands_loading: true,
      in_progress: null,
      active: true,
      items: [],
      brands: [],
      sites: [],
      categories: [],
      headers: [
        {
          text: this.$i18n.t("id"),
          value: "id",
          sortable: true,
          width: "100"
        },
        {
          text: this.$i18n.tc("brand", 1),
          value: "brand",
          sortable: true
        },
        {
          text: this.$i18n.tc("product", 2),
          value: "count.products",
          sortable: true,
          width: "120",
          centered: true
        },
        {
          text: this.$i18n.tc("sku", 2),
          value: "count.skus",
          sortable: true,
          width: "80",
          centered: true
        },
        {
          text: this.$i18n.t("noindex"),
          value: "noindex",
          sortable: true,
          width: "100",
          centered: true
        },
        {
          text: this.$i18n.t("rank"),
          value: "rank",
          sortable: true,
          width: "100",
          centered: true
        },
        {
          text: this.$i18n.t("created_at"),
          value: "created_at.datetime",
          sortable: true,
          width: "200"
        },
        {
          text: this.$i18n.t("actions"),
          value: "actions",
          sortable: false,
          width: "80",
          centered: true
        }
      ]
    };
  },
  watch: {
    active() {
      this.getBrands();
    },
    search() {
      this.filterSearch();
    }
  },
  created() {
    this.updateTitle(this.$i18n.tc("brand", 2));
  },
  mounted() {
    this.getBrands();
  },
  methods: {
    showMobile() {
      if (this.$device.mobile) {
        this.searchFocused = true;
        this.$refs.search.focus();
      }
    },
    hideMobile() {
      if (this.$device.mobile) {
        this.searchFocused = false;
      }
    },
    confirmDeleting(id) {
      this.$buefy.dialog.confirm({
        message: `<b>${this.$t("are_you_sure")}</b>`,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        onConfirm: () => this.deleteBrand(id)
      });
    },
    filterSearch() {
      if (this.search.length) {
        this.brands = this.items.filter(
          a =>
            JSON.stringify(a)
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) != -1
        );
      } else {
        this.brands = this.items;
      }
      this.total = this.brands.length;
    },
    getBrands() {
      if (this.$can("read", "catalog.brands")) {
        this.brands_loading = true;
        let query = "";
        if (!this.active) query += "?active=0";

        this.$axios
          .get("brands" + query)
          .then(res => {
            this.items = res.data.list ? res.data.list : [];
            this.filterSearch();
            this.sites = res.data.filters.sites ? res.data.filters.sites : [];
          })
          .catch(e => this.clientError(e))
          .finally(() => (this.brands_loading = false));
      }
    },
    deleteBrand(id) {
      this.in_progress = id;
      this.$axios
        .delete("brands/" + id)
        .then(() => {
          this.items = this.items.filter(i => i.id !== id);
          this.filterSearch();
          this.$root.notify(this.$t("successfully_deleted"), "is-success");
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.in_progress = null));
    },
    RestoreBrands(id) {
      this.in_progress = id;
      this.$axios
        .patch("brands/" + id + "/restore")
        .then(() => {
          this.items = this.items.filter(i => i.id !== id);
          this.filterSearch();
          this.$root.notify(this.$t("successfully_restored"), "is-success");
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.in_progress = null));
    }
  }
};
</script>
